// Generated by Framer (4175fb7)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, useActiveVariantCallback, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["Kzb3QzY2x", "R_EPt680x"];

const serializationHash = "framer-iReyo"

const variantClassNames = {Kzb3QzY2x: "framer-v-qdp9bx", R_EPt680x: "framer-v-1wp1e2j"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const toResponsiveImage = (value) => {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


const transition1 = {delay: 0, duration: 0.2, ease: [0.5, 0, 0.88, 0.77], type: "tween"}

const transition2 = {delay: 0, duration: 1, ease: [0.44, 0, 0.56, 1], type: "tween"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {"Variant 1": "Kzb3QzY2x", "Variant 2": "R_EPt680x"}

const getProps = ({height, id, image, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "Kzb3QzY2x", Wkx0SmUkS: image ?? props.Wkx0SmUkS ?? {src: "https://framerusercontent.com/images/LvdOe8cxAHJ3SbiZufwjypg8oYs.png?scale-down-to=512", srcSet: "https://framerusercontent.com/images/LvdOe8cxAHJ3SbiZufwjypg8oYs.png?scale-down-to=1024 819w,https://framerusercontent.com/images/LvdOe8cxAHJ3SbiZufwjypg8oYs.png 1080w"}} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;image?: {src: string; srcSet?: string}; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, Wkx0SmUkS, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "Kzb3QzY2x", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onMouseEnterlgcwko = activeVariantCallback(async (...args) => {
setVariant("R_EPt680x")
})

const onMouseLeave19jjdj9 = activeVariantCallback(async (...args) => {
setVariant("Kzb3QzY2x")
})

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1} {...addPropertyOverrides({R_EPt680x: {value: transition2}}, baseVariant, gestureVariant)}><Image {...restProps} background={{alt: "", fit: "fill", intrinsicHeight: 675, intrinsicWidth: 540, pixelHeight: 1350, pixelWidth: 1080, sizes: componentViewport?.width || "100vw", ...toResponsiveImage(Wkx0SmUkS)}} className={cx(serializationHash, ...sharedStyleClassNames, "framer-qdp9bx", className, classNames)} data-framer-name={"Variant 1"} data-highlight layoutDependency={layoutDependency} layoutId={"Kzb3QzY2x"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onMouseEnter={onMouseEnterlgcwko} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{filter: "none", opacity: 0.5, WebkitFilter: "none", ...style}} variants={{R_EPt680x: {filter: "brightness(1.1)", opacity: 1, WebkitFilter: "brightness(1.1)"}}} {...addPropertyOverrides({R_EPt680x: {"data-framer-name": "Variant 2", onMouseLeave: onMouseLeave19jjdj9}}, baseVariant, gestureVariant)}/></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-iReyo.framer-1ntyr4e, .framer-iReyo .framer-1ntyr4e { display: block; }", ".framer-iReyo.framer-qdp9bx { height: 191px; overflow: visible; position: relative; width: 280px; }", ".framer-iReyo.framer-v-1wp1e2j.framer-qdp9bx { aspect-ratio: 1.4659685863874345 / 1; height: var(--framer-aspect-ratio-supported, 191px); }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 191
 * @framerIntrinsicWidth 280
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"R_EPt680x":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"Wkx0SmUkS":"image"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerdidqGxGf6: React.ComponentType<Props> = withCSS(Component, css, "framer-iReyo") as typeof Component;
export default FramerdidqGxGf6;

FramerdidqGxGf6.displayName = "Gallery Image";

FramerdidqGxGf6.defaultProps = {height: 191, width: 280};

addPropertyControls(FramerdidqGxGf6, {variant: {options: ["Kzb3QzY2x", "R_EPt680x"], optionTitles: ["Variant 1", "Variant 2"], title: "Variant", type: ControlType.Enum}, Wkx0SmUkS: {__defaultAssetReference: "data:framer/asset-reference,LvdOe8cxAHJ3SbiZufwjypg8oYs.png?originalFilename=5d20eaf12a75b27b57f2d1.png&preferredSize=auto", title: "Image", type: ControlType.ResponsiveImage}} as any)

addFonts(FramerdidqGxGf6, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})